@use "./utils/imports/import-fonts.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.66em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Now", sans-serif, "Font Awesome 6 Free";
}

::-webkit-scrollbar {
	width: 7.5px;
}

::-webkit-scrollbar-track {
	background: #fff;
	border-left: 1px solid #e0e0e0;
}

::-webkit-scrollbar-thumb {
	background: #b7b7b7;
	border-radius: 5px;

	&:hover {
		background: #9b9b9b;
	}
}

li::marker {
	color: theme('colors.main');
}

.colored-underline {
	text-decoration: underline;
	text-decoration-color: theme('colors.main');
	text-decoration-thickness: 2px;
	text-underline-offset: 6px;
}

/* Transition CSS */
.image-container {
	transition: width 0.5s ease-in-out;
	/* Adjust the time as needed */
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url("./assets/icons/global/Icon-chevron-down.svg");
	/* Custom arrow */
	background-repeat: no-repeat;
	background-position: right 10px center;
	background-size: 12px;
}

.custom-checkbox-wrapper {
	display: inline-block;
	vertical-align: middle;

	.custom-checkbox {
		opacity: 0;
		position: absolute;
	}

	.custom-checkbox+label {
		position: relative;
		cursor: pointer;
		padding-left: 26px;
		color: #CEA95A; // Text color

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 20px;
			height: 20px;
			border: 1px solid #CEA95A; // Border color
			border-radius: 3px;
			background-color: transparent; // Default background color
		}

		&:after {
			content: '✔'; // Checkmark character
			position: absolute;
			top: 0px;
			left: 3px;
			font-size: 18px;
			color: white; // Checkmark color
			transition: all 0.2s;
			opacity: 0;
		}
	}

	.custom-checkbox:checked+label:before {
		background-color: #CEA95A; // Checked background color
	}

	.custom-checkbox:checked+label:after {
		opacity: 1; // Show checkmark when checked
	}
}

.kr-embedded .kr-payment-button {
	background-color: #cea95a !important;
	border-color: white !important;
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	flex: 1;
	min-width: 0;
	padding: 13px 8px;
	border-radius: 0.375rem;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
	padding-left: var(--PhoneInputCountrySelectArrow-marginLeft);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon+.PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.image-border-effect {
	position: relative;
	z-index: 1;

	&::before {
		content: " ";
		position: absolute;
		z-index: 10;
		top: 20px;
		left: 20px;
		right: 20px;
		bottom: 20px;
		border: 1px solid #CEA95A;
		//   transition: all 0.6s ease-in-out;
	}

	// &:hover::before {
	//   top: 10px;
	//   left: 10px;
	//   right: 10px;
	//   bottom: 10px;
	// }
}

.image-border-effect-gallery {
	position: relative;
	z-index: 1;

	&::before {
		content: " ";
		position: absolute;
		z-index: 10;
		top: 20px;
		left: 20px;
		right: 20px;
		bottom: 100px;
		border: 1px solid #CEA95A;
		//   transition: all 0.6s ease-in-out;
	}
}

.border-cta-lines {
	position: relative;
	display: inline-block;
}

.border-cta-lines::before,
.border-cta-lines::after {
	position: absolute;
	width: 30px;
	display: block;
	border-bottom: solid 1px;
	transition: transform 250ms ease-in-out;
	content: " ";
	height: 1px;
	border-color: currentColor;
	top: 50%;
	transform: translateY(-50%) scaleX(1);
}

.border-cta-lines::before {
	left: -20px;
	transform: scaleX(1);
}

.border-cta-lines::after {
	right: -20px;
	transform: scaleX(1);
}

.border-cta-lines-2::before,
.border-cta-lines-2::after {
	border-bottom: solid 2px;
	height: 2px;
}


.player-wrapper {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.react-player {
	height: 100% !important;
	width: 177.77777778vh !important;
	min-width: 100% !important;
	min-height: 56.25vw !important;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.image-gallery-slide img {
	border-radius: 0px !important;
	position: relative;
	z-index: 1;
}

.image-gallery-icon {
	z-index: 100 !important;
}