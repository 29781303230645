.image-gallery-slide img {
    border-radius: 12px;
}

/* Change the color of the left and right arrow buttons */
.image-gallery-icon:hover {
    color: #CEA95A;
}

/* Change the color of the play and fullscreen buttons */
.image-gallery-play-button:hover,
.image-gallery-fullscreen-button:hover {
    color: #CEA95A;
    background-color: transparent;
}

.image-gallery-icon {
    filter: drop-shadow(0 1px 1px #1a1a1a);
}

.image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.05);
}