$source-font-path: "../../resources/fonts/now" !default;
@import url("https://p.typekit.net/p.css?s=1&k=mki3lej&ht=tk&f=22798.22800&a=84447811&app=typekit&e=css");

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Black.otf") format("opentype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Bold.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Medium.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Light.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/Now-Thin.otf") format("opentype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Black.otf") format("opentype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Bold.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Medium.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Light.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: "Now";
    src: url("#{$source-font-path}/NowAlt-Thin.otf") format("opentype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "grafolita-script";
    src: url("https://use.typekit.net/af/d24dd2/00000000000000007735a463/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),
         url("https://use.typekit.net/af/d24dd2/00000000000000007735a463/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),
         url("https://use.typekit.net/af/d24dd2/00000000000000007735a463/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
}

@font-face {
    font-family: "grafolita-script";
    src: url("https://use.typekit.net/af/41abd6/00000000000000007735a45e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
         url("https://use.typekit.net/af/41abd6/00000000000000007735a45e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
         url("https://use.typekit.net/af/41abd6/00000000000000007735a45e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

.tk-grafolita-script {
    font-family: "grafolita-script", sans-serif;
}